import { memo } from 'react';

const RemindIcon = () => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    viewBox="226.17 168.3 40 30"
    xmlSpace="preserve"
    width="40"
    height="30"
  >
    <g>
      <path
        fill="#FFFFFF"
        d="M246.613,171.908c-4.561,0-8.361,2.281-9.502,5.195c-2.914,1.014-4.941,3.801-4.941,7.095
        c0,4.181,3.294,7.602,7.476,7.602c1.394,0,2.787-0.38,3.928-1.141l0.38,3.294c0.127,0.761,1.014,
        1.014,1.521,0.381l3.167-4.308 c0.254,0,0.507,0.126,0.761,0.126c2.027,0,3.801-1.267,
        4.435-3.167c3.547-0.254,6.334-2.661,6.334-5.828 c0-2.661-2.026-4.941-4.94-5.575C253.582,173.428,250.288,
        171.908,246.613,171.908z"
      />
    </g>
  </svg>
);

export default memo(RemindIcon);

import { IcFluentMegaphone24Filled, TopicResourceType } from '@flipgrid/flipkit';
import classNames from 'classnames';

import { handleError } from '~/helper/imgOnError';
import topicImageCircleStyles from '~/styles/components/MyVideos/TopicImageCircle.css';

import type { SyntheticEvent } from 'react';
import type { Topic, TopicTemplate } from 'types';

export const links = () => [{ rel: 'stylesheet', href: topicImageCircleStyles }];

type Props = {
  size?: 55;
  topic?: Topic | TopicTemplate;
};

const TopicImageCircle = ({ size, topic }: Props) => {
  const noQueryParam =
  topic?.focus?.type === TopicResourceType.Other ||
  topic?.focus?.image_url?.includes('adobe') ||
  topic?.focus?.image_url?.includes('bitmoji');

  return topic?.focus?.image_url ?
  <img
    className={classNames('topicImageCircle__image', { [`-size-${size}`]: size })}
    src={noQueryParam ? topic.focus.image_url : `${topic.focus.image_url}?size=xsmall`}
    alt=""
    onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} /> :


  <div className={classNames('topicImageCircle__image -default', { [`-size-${size}`]: size })}>
      <IcFluentMegaphone24Filled className="topicImageCircle__defaultIcon" />
    </div>;

};

export default TopicImageCircle;
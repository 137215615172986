import { Tooltip } from '@flipgrid/flipkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  url: string;
};

function ShareToGoogleClassroomButton({ url }: Props) {
  const { t } = useTranslation();
  // eslint-disable-next-line
  // @ts-ignore
  const [apiLoaded, setApiLoaded] = useState(typeof gapi !== 'undefined' && gapi);
  const [iframeIsFocused, setIframeIsFocused] = useState(false);
  const googleClassroom = useRef(null);

  const loadGoogleApi = useCallback(() => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/platform.js';
    script.onload = () => {
      setApiLoaded(true);
    };
    document.body.appendChild(script);
  }, [setApiLoaded]);

  useEffect(() => {
    if (apiLoaded) {
      // eslint-disable-next-line
      // @ts-ignore
      gapi.sharetoclassroom.render(googleClassroom.current, { url, size: '44' });
    }
  }, [apiLoaded, url]);

  useEffect(() => {
    if (!apiLoaded) loadGoogleApi();
  }, [apiLoaded, loadGoogleApi]);

  useEffect(() => {
    let interval: TSFix = null;

    if (apiLoaded && googleClassroom.current) {
      // eslint-disable-next-line
      // @ts-ignore
      const iframe = googleClassroom.current.lastChild;
      iframe.name = 'share_to_google_classroom';
      iframe.title='share_to_google_classroom';
      iframe.role = 'none';
      interval = setInterval(() => {
        setIframeIsFocused(document.activeElement === iframe);
      }, 250);
    }

    return () => clearInterval(interval);
  }, [apiLoaded]);

  if (!apiLoaded) return null;

  return (
    <Tooltip label={t('socialLinks.shareToGoogleClassroom')}>
      <div
        className={`socialLinks__googleClassroom ${iframeIsFocused ? '-focusedWithin' : ''}`}
        aria-label={t('socialLinks.shareToGoogleClassroom')}
      >
        <div data-testid="share-to-google-classroom-button" ref={googleClassroom} id="google-classroom" />
      </div>
    </Tooltip>
  );
}

export default ShareToGoogleClassroomButton;

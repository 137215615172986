import {
  Anchor,
  Button,
  IcFluentArrowDownload24Regular,
  IcFluentCode24Regular,
  Tooltip,
  IcFluentQrCode24Regular,
  IcFluentCopy24Regular,
  Popover,
  IcFluentMail24Regular } from
'@flipgrid/flipkit';
import classNames from 'classnames';
import { Fragment, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ShareToGoogleClassroomButton from './ShareToGoogleClassroomButton';
import ShareToTeamsButton from './ShareToTeamsButton';
import { openPopupCenter } from '../../../helper/popUpHelper.client';
import useTooltipTrigger from '../../../hooks/useTooltipTrigger';
import RemindIcon from '../../../svg/social/RemindIcon';
import TwitterIcon from '../../../svg/social/TwitterIcon';
import MakeQR from '../../Utility/MakeQR';
import externalLinks from '~/constants/externalLinks';
import GlobalContext from '~/contexts/globalContext';
import socialLinksStyles from '~/styles/components/Share/SocialLinks.css';

export const links = () => [{ rel: 'stylesheet', href: socialLinksStyles }];

type Props = {
  className?: string;
  isLoading?: boolean;
  url: string;
  aspectRatio?: string;
  download?: {url: string;displayName?: string;};
  qr?: {url: string;ar?: boolean;displayName?: string;};
  hideQrCodeBtn?: boolean;
  hideCopyShareLinkBtn?: boolean;
  type: 'Comment' | 'Group' | 'MyVideos' | 'Response' | 'Topic' | 'Mixtape' | 'MixtapeResponse';
  token: string;
};

const SocialLinks = ({
  aspectRatio,
  className,
  hideQrCodeBtn,
  hideCopyShareLinkBtn = true,
  isLoading,
  url,
  download,
  qr,
  type,
  token
}: Props) => {
  const { t } = useTranslation();
  const [embedCodeCopied, setEmbedCodeCopied] = useState(false);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [isQRCodeVisible, setIsQRCodeVisible] = useState(false);
  const embedCodeButtonRef = useRef(null);
  const copyShareLinkButtonRef = useRef(null);
  const buttonRef = useRef(null);
  const { announceLiveMessage, breakPoints } = useContext(GlobalContext);

  useTooltipTrigger(shareLinkCopied, copyShareLinkButtonRef);
  useTooltipTrigger(embedCodeCopied, embedCodeButtonRef);

  const generateEmbedURL = () => {
    let embedHeight = 600;
    let embedWidth = 600;

    if (aspectRatio === '16:9') {
      embedHeight = 338;
    } else if (aspectRatio === '9:16') {
      embedHeight = 676;
      embedWidth = 380;
    }

    return `<iframe
    width="${embedWidth}"
    height="${embedHeight}"
    frameBorder="0"
    src="${url}?embed=true"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    allow="microphone; camera; display-capture"></iframe>`;
  };

  const announceLinkCopied = (copyUrl: string, copyMessage: string, setCopiedFunction: (arg0: boolean) => void) => {
    navigator.clipboard.writeText(copyUrl);
    setCopiedFunction(true);
    setTimeout(() => {
      setCopiedFunction(false);
    }, 5000);

    announceLiveMessage(copyMessage);
  };

  const postToTwitter = () => {
    const shareMessage = `Check%20out%20this%20@MicrosoftFlip%20${type}!%20` + encodeURIComponent(url);
    const tweetLink = externalLinks.TwitterShare(shareMessage);
    openPopupCenter(tweetLink, 'Share', 500, 250);
  };

  const shareToEmail = () => {
    let emailSubject;
    let emailBody;

    switch (type) {
      case 'Mixtape':
        emailSubject = t('socialLinks.emailSubjectMixtape');
        emailBody = t('socialLinks.emailBodyMixtape', { url });
        break;
      case 'Topic':
        emailSubject = t('socialLinks.emailSubjectTopic');
        emailBody = t('socialLinks.emailBodyTopic', { url });
        break;
      case 'Response':
      case 'MyVideos':
      default:
        emailSubject = t('socialLinks.emailSubject');
        emailBody = t('socialLinks.emailBody', { url });
        break;
    }

    emailBody += t('socialLinks.flipExplained');

    window.location.href = `mailto:?to=&subject=${emailSubject}&body=${emailBody}`;
  };

  return (
    <Fragment>
      <div className={classNames('socialLinks', { className })}>
        {!isLoading &&
        <ul>
            {(type === 'Response' || type === 'MyVideos' || type === 'Mixtape' || type === 'Topic') &&
          <Tooltip label={t('shared.shareToEmail')}>
                <li role="none">
                  <Button
                aria-label={t('shared.shareToEmail')}
                data-testid="socialLinks__button__shareToEmail"
                onClick={shareToEmail}
                icon={<IcFluentMail24Regular width="30px" height="30px" />}
                size={breakPoints.isScreenMedium ? '44' : '36'} />

                </li>
              </Tooltip>}

            {download &&
          <Tooltip label={t('common.downloadVideo')}>
                <li role="none">
                  <Anchor
                aria-label={t('common.downloadVideo')}
                data-testid="socialLinks__anchor__download"
                icon={<IcFluentArrowDownload24Regular width="30px" height="30px" />}
                variant="button"
                to={`${download.url}?download=true&filename=${download.displayName}`}
                size={breakPoints.isScreenMedium ? '44' : '36'}
                newTab />

                </li>
              </Tooltip>}

            {!hideCopyShareLinkBtn &&
          <Tooltip label={shareLinkCopied ? t('socialLinks.shareLinkCopied') : t('socialLinks.copyShareLink')}>
                <li role="none">
                  <Button
                aria-label={t('socialLinks.copyShareLink')}
                data-testid="socialLinks__button__copyShareLink"
                icon={<IcFluentCopy24Regular />}
                onClick={() => announceLinkCopied(url, t('socialLinks.shareLinkCopied'), setShareLinkCopied)}
                ref={copyShareLinkButtonRef}
                size={breakPoints.isScreenMedium ? '44' : '36'} />

                </li>
              </Tooltip>}

            {qr && !hideQrCodeBtn &&
          <li role="none">
                <Tooltip label={t('socialLinks.shareQrCode')}>
                  <Button
                className="socialLinks__shareQrCodeBtn"
                theme="transparent"
                ref={buttonRef}
                onClick={() => setIsQRCodeVisible((prev) => !prev)}
                icon={<IcFluentQrCode24Regular />}
                size={breakPoints.isScreenMedium ? '44' : '36'}
                aria-describedby={isQRCodeVisible ? 'socialLinksPopover' : null}
                aria-label={t('socialLinks.shareQrCode')}
                data-testid="socialLinks__button__shareQrCode" />

                </Tooltip>
                <Popover
              id="socialLinksPopover"
              targetRef={buttonRef}
              onDismiss={() => setIsQRCodeVisible(false)}
              isVisible={isQRCodeVisible}>

                  <MakeQR allowsAr={qr.ar} url={qr.url} name={qr.displayName} token={token} />
                </Popover>
              </li>}

            <ShareToTeamsButton url={url} />
            <li role="none">
              <ShareToGoogleClassroomButton url={url} />
            </li>
            <li role="none">
              <Tooltip label={t('socialLinks.shareOnRemind')}>
                <Anchor
                to={externalLinks.ShareOnRemind(url)}
                className="socialLinks__remind"
                data-testid="socialLinks__anchor__shareRemind"
                aria-label={t('socialLinks.shareOnRemind')}
                icon={<RemindIcon />}
                newTab
                theme="transparent"
                variant="button"
                size={breakPoints.isScreenMedium ? '44' : '36'} />

              </Tooltip>
            </li>
            <Tooltip label={t('shared.postToTwitter')}>
              <li role="none">
                <Button
                className="socialLinks__twitter"
                aria-label={t('socialLinks.postTopicToTwitter')}
                data-testid="socialLinks__button__twitter"
                onClick={postToTwitter}
                icon={<TwitterIcon />}
                theme="transparent"
                size={breakPoints.isScreenMedium ? '44' : '36'} />

              </li>
            </Tooltip>
            <Tooltip label={embedCodeCopied ? t('socialLinks.embedCodeCopied') : t('socialLinks.copyEmbedCode')}>
              <li role="none">
                <Button
                className="socialLinks__embed"
                aria-label={t('socialLinks.clickToCopyEmbed')}
                data-testid="socialLinks__button__copy"
                icon={<IcFluentCode24Regular />}
                onClick={() =>
                announceLinkCopied(generateEmbedURL(), t('socialLinks.embedCodeCopied'), setEmbedCodeCopied)}

                ref={embedCodeButtonRef}
                size={breakPoints.isScreenMedium ? '44' : '36'}
                theme="transparent" />

              </li>
            </Tooltip>
          </ul>}

      </div>
    </Fragment>);

};

export default SocialLinks;
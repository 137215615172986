import { Tooltip } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HandleIds from '~/constants/handleIds';

import type { RouteTyping } from 'types';

type ShareToTeamsButtonProps = {
  url: string;
};

const ShareToTeamsButton = ({ url }: ShareToTeamsButtonProps) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;

  const teamsCompatible = ua && !ua.mobile && (ua.edge || ua.chrome);
  const [teamsLoaded, setTeamsLoaded] = useState(
    // @ts-expect-error
    !!(window.shareToMicrosoftTeams && typeof window.shareToMicrosoftTeams.renderButtons === 'function'),
  );

  const loadTeamsScript = () => {
    const teamsScript = document.createElement('script');
    teamsScript.src = 'https://teams.microsoft.com/share/launcher.js';
    teamsScript.onload = () => {
      setTeamsLoaded(
        // @ts-expect-error
        !!(window.shareToMicrosoftTeams && typeof window.shareToMicrosoftTeams.renderButtons === 'function'),
      );
    };
    document.body.appendChild(teamsScript);
  };

  useEffect(() => {
    if (!teamsLoaded && teamsCompatible) loadTeamsScript();
  }, [teamsLoaded, teamsCompatible]);

  useEffect(() => {
    // @ts-expect-error
    if (teamsCompatible && teamsLoaded) shareToMicrosoftTeams.renderButtons();
  }, [teamsCompatible, teamsLoaded, url]);

  if (!teamsCompatible || !teamsLoaded) return null;

  return (
    <li role="none">
      <Tooltip label={t('shareToTeamsButton.shareToMicrosoftTeams')}>
        <div
          aria-label={t('shareToTeamsButton.shareToMicrosoftTeams')}
          className="teams-share-button"
          data-href={url}
          data-icon-px-size="65"
          data-testid="share-to-microsoft-teams"
        />
      </Tooltip>
    </li>
  );
};

export default ShareToTeamsButton;
